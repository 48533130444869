/* eslint-disable no-use-before-define */
import React, { useState, useEffect, useCallback } from 'react';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { useLocation, matchPath } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Chip,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  Typography,
  makeStyles
} from '@material-ui/core';
import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';
import BorderAllIcon from '@material-ui/icons/BorderAll';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import HomeIcon from '@material-ui/icons/Home';
import DashboardIcon from '@material-ui/icons/Dashboard';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
import ScheduleOutlinedIcon from '@material-ui/icons/ScheduleOutlined';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import SpeakerNotesOutlinedIcon from '@material-ui/icons/SpeakerNotesOutlined';
import FeedbackOutlinedIcon from '@material-ui/icons/FeedbackOutlined';
import GridOnIcon from '@material-ui/icons/GridOn';
import AssignmentTurnedInOutlinedIcon from '@material-ui/icons/AssignmentTurnedInOutlined';
import axios from 'src/utils/terotechDataservice';
import {
  Briefcase as BriefcaseIcon,
  Calendar as CalendarIcon,
  ShoppingCart as ShoppingCartIcon,
  Folder as FolderIcon,
  BarChart as BarChartIcon,
  Lock as LockIcon,
  UserPlus as UserPlusIcon,
  AlertCircle as AlertCircleIcon,
  Trello as TrelloIcon,
  User as UserIcon,
  Layout as LayoutIcon,
  Edit as EditIcon,
  DollarSign as DollarSignIcon,
  Mail as MailIcon,
  MessageCircle as MessageCircleIcon,
  PieChart as PieChartIcon,
  Share2 as ShareIcon,
  Users as UsersIcon,
  Database as DbIcon,
  Package as PackageIcon
} from 'react-feather';

import Logo from 'src/components/Logo';
import useAuth from 'src/hooks/useAuth';
import NavItem from './NavItem';

import { useTranslation, withNamespaces } from 'react-i18next';
import { random } from 'lodash';

function renderNavItems({ items, pathname, plant, idprofilo, depth = 0 }) {
  var pro = idprofilo;
  return (
    <List disablePadding key={items.title + random()}>
      {items.reduce(
        (acc, item) =>
          reduceChildRoutes({ acc, item, pathname, plant, idprofilo, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, plant, idprofilo, depth }) {
  const key = item.title + depth;
  if (item.permission && !item.permission.find(x => x == idprofilo)) {
    return acc;
  }
  if (item.hideWo && !item.plantWo) {
    return acc;
  }

  if (item.items) {
    //var open = matchPath(pathname, {
    //  path: item.href,
    //  exact: false
    //});

    // var  open = item.items.find(x => x.href == pathname) || item.items.find(x => x.items && x.items.find(y => y.href ==pathname));
    var open =
      item.items.find(x => pathname.startsWith(x.href)) ||
      item.items.find(
        x => x.items && x.items.find(y => pathname.startsWith(y.href))
      );
    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key + random()}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
          idprofilo,
          plant
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key + random()}
        title={item.title}
      />
    );
    // Remove item from menu if isn't part of actual user plant
    //if (item.hideCheck && item.plant != plant) { acc.pop() }
    if (item.hideCheck && !item.plant) {
      acc.pop();
    }
    if (item.hideWo && !item.plantWo) {
      acc.pop();
    }
  }

  return acc;
}

function findPermissionItem({ data, idprofilo, idPlant }) {
  if (data != undefined) {
    var find = data.permission.find(x => x == idprofilo);
    if (find != undefined) {
      return true;
    }
  }
  return false;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const isMountedRef = useIsMountedRef();
  const location = useLocation();
  const { user } = useAuth();
  const { t, i18n } = useTranslation();
  const [plantPermission, setPlantCheckListPermission] = useState(false);
  const [plantWoPermission, setPlantWoPermission] = useState(false);
  //const plantPermission = 'MODENA';

  console.log('user: ', user);
  //user.idplant
  /*
  {
    "avatar": null,
    "idprofilo": 8,
    "idplant": 4,
    "language": "it-IT",
    "iduser": 313,
    "email": "usertest6@radamdm.onmicrosoft.com",
    "name": "usertest6",
    "endpointRoot": false,
    "tier": "Premium"
}
*/
  /*  object user */
  const getPlant = useCallback(async () => {
    try {
      if (user.gestioneRisorse === true) {
        setPlantWoPermission(true);
      } else {
        setPlantWoPermission(false);
      }
      const response = await axios.get(`plant/items/${user.idplant}`);
      console.log('responseChecklist', response.data);
      if (isMountedRef.current) {
        setPlantCheckListPermission(response.data.chklst);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);

  useEffect(() => {
    getPlant();
  }, [getPlant]);
  const sections1 = [
    {
      permission: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      items: [
        {
          title: t('Menu.Home'),
          icon: HomeIcon,
          href: '/app/reports/dashboard-alternative',
          permission: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
        }
      ]
    },

    {
      permission: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      items: [
        {
          title: 'Dashboard',
          icon: DashboardIcon,
          href: '/app/reports/redirect-ext',
          //href: (user.idplant==5 ? 'http://idashboard.cnh.com:6700/idashboards/viewer?guestuser=guest&dashID=211': '#'),
          /*
            onClick:(user.idplant==5 ?
            window.open('http://idashboard.cnh.com:6700/idashboards/viewer?guestuser=guest&dashID=211', '_blank') : '#'),
            */
          permission: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
        }
      ]
    },

    {
      subheader: t('Menu.Plant Layout'),
      permission: [1, 2, 8],
      items: [
        {
          title: t('Menu.Plant Layout'),
          icon: GridOnIcon,
          href: '/app/plantlayout'
        }
      ]
    },
    //Management PM
    {
      title: 'Management PM Padre',
      permission: [1, 2, 6, 8],
      items: [
        {
          title: t('Management PM'),
          items: [
            {
              title: t('StutturaMacchina'), // 'Dashboard',
              icon: AccountTreeIcon,
              href: '/app/mgmt/equipStructure',
              permission: [1, 2, 6, 8]
            },
            {
              title: t('MetodoSMP'),
              icon: DescriptionOutlinedIcon,
              href: '/app/mgmt/smp',
              permission: [1, 2, 6, 8]
            },
            {
              id: 'checklist',
              title: t('Checklist.Chklist'),
              icon: AssignmentTurnedInOutlinedIcon,
              href: '/app/mgmt/checklist',
              permission: [1, 2, 8],
              hideCheck: true,
              plant: plantPermission
            },
            {
              title: t('Manutenzione'),
              icon: ScheduleOutlinedIcon,
              href: '/app/mgmt/maintenance',
              permission: [1, 2, 6, 8]
            },
            {
              title: t('Menu.HitsMaintenance'),
              icon: ScheduleOutlinedIcon,
              href: '/app/mgmt/hitsmaint',
              permission: [1, 8]
            },
            {
              title: t('CalendarioPM'),
              icon: CalendarTodayIcon,
              href: '/app/mgmt/PMcalendar',
              permission: [1, 2, 6, 8]
            },
            {
              title: t('Calendario Checklist'),
              icon: CalendarTodayIcon,
              href: '/app/mgmt/CKcalendar',
              permission: [1, 2, 6, 8],
              hideCheck: true,
              plant: plantPermission
            },
            {
              title: t('Menu.Workorders'),
              icon: ListAltOutlinedIcon,
              href: '/app/mgmt/workorders',
              permission: [1, 2, 6, 8]
            },
            {
              title: 'Report checklist',
              icon: ListAltOutlinedIcon,
              href: '/app/mgmt/reportChecklist',
              permission: [1, 2, 6, 8],
              hideCheck: true,
              plant: plantPermission
            },
            /* {
              title: t('CalendarioLub'),
              icon: CalendarTodayIcon,
              href: '/app/mgmt/PMlubricant'
            },  */
            {
              title: t('Machine Ledger'),
              icon: BorderAllIcon,
              href: '/app/mgmt/MachineLedger',
              permission: [1, 2, 6, 8]
            },
            {
              title: t('Magazzino'),
              icon: PackageIcon,
              href: '/app/mgmt/warehouse',
              permission: [1, 2, 8]
            },
            {
              title: t('Cataloghi'),
              icon: FolderIcon,
              permission: [1, 2, 8],
              items: [
                {
                  title: t('Strumenti di Controllo'),
                  href: '/app/mgmt/catalogs/controltools'
                },
                {
                  title: t('Lubrificanti'),
                  href: '/app/mgmt/catalogs/lubfrificants'
                },
                {
                  title: t('Materiali Ausiliari'),
                  href: '/app/mgmt/catalogs/auxiliarymaterials'
                },
                {
                  title: t('Attrezzi'),
                  href: '/app/mgmt/catalogs/tools'
                }
              ]
            }
          ]
        }
      ]
    },
    //Management AM
    {
      title: 'Management AM Padre',
      permission: [1, 3, 8],
      items: [
        {
          title: t('Management AM'),
          items: [
            {
              title: 'Reporting AM', // 'Dashboard',
              icon: CalendarTodayIcon,
              //href: '/app/mgmt/'
              href: '/app/mgmtAM/AMReporting'
            },
            {
              title: t('Calendar AM'), // 'Dashboard',
              icon: CalendarTodayIcon,
              //href: '/app/mgmt/'
              href: '/app/mgmtAM/AMCalendar'
            },
            {
              title: t('StutturaMacchina'), // 'Dashboard',
              icon: AccountTreeIcon,
              //href: '/app/mgmt/'
              href: '/app/mgmtAM/equipStructure/equipSelection'
            },
            {
              title: t('Metodo SOP'),
              icon: ReceiptIcon,
              href: '/app/mgmtAM/sop'
            },
            {
              title: t('Manutenzione'),
              icon: ReceiptIcon,
              href: '/app/mgmtAM/maintenance'
            },
            {
              title: t('Percorsi'),
              icon: ReceiptIcon,
              href: '/app/mgmtAM/path/equipSelection'
            },
            {
              title: t('CILR'),
              icon: ReceiptIcon,
              href: '/app/mgmtAM/cilr/equipSelection'
            }
          ]
        }
      ]
    },
    //Organizzazione
    {
      title: 'Organizzazione Padre',
      permission: [1, 6, 8],
      items: [
        {
          title: t('Organizzazione'),
          items: [
            {
              title: t('Definizione Cicli'),
              icon: FormatListNumberedIcon,
              href: '/app/management/products',
              permission: [1, 6, 8],
              hideWo: true,
              plantWo: plantWoPermission === false,
              items: [
                {
                  title: t('Menu.Workorders'),
                  href: '/app/planning/maintenanceCycles/view/workorders',
                  hideWo: true,
                  plantWo: plantWoPermission === false
                },
                {
                  title: t('Checklist.Chklist'),
                  href: '/app/planning/maintenanceCycles/view/checklists',
                  hideCheck: true,
                  plant: plantPermission
                }
              ]
            },
            {
              title: t('Gestione Operatori'),
              icon: AssignmentIndIcon,
              href: '/app/planning/mgmtOperators',
              permission: [1, 8]
            },
            {
              title: t('Gestione Squadre'),
              icon: PeopleAltIcon,
              href: '/app/planning/mgmtTeams',
              permission: [1, 8]
            },
            {
              title: t('Assegnazioni'),
              icon: FolderIcon,
              href: '/app/management/products',
              permission: [1, 6, 8],
              items: [
                {
                  title: t('Workorders'),
                  href: '/app/planning/workorder/assign',
                  hideWo: true,
                  plantWo: plantWoPermission
                },
                {
                  title: t('Ewo'),
                  href: '/app/planning/ewo/assign'
                },
                {
                  title: t('Tag'),
                  href: '/app/planning/tag/assign'
                }
              ]
            },
            {
              title: t('Causa Radice'),
              icon: ReceiptIcon,
              href: '/app/management/RootCauseAnalysis',
              permission: [1, 6, 8]
            },
            {
              title: t('Nuovo TAG'),
              icon: SpeakerNotesOutlinedIcon,
              href: '/app/planning/tag/equipment?view=desktop',
              permission: [1, 8]
            },
            {
              title: t('Nuova EWO'),
              icon: FeedbackOutlinedIcon,
              href: '/app/planning/ewo/equipment?view=desktop',
              permission: [1, 8]
            },
            {
              title: t('Menu.EwoHistory'),
              icon: ListAltOutlinedIcon,
              href: '/app/planning/ewo/ewohistory',
              permission: [1, 6, 8]
            }
          ]
        }
      ]
    },

    /*    {
              subheader: t('Esecuzione'),
              permission: [1, 2],
              items: [
                {
                  title: t('Interventi Programmati'),
                  href: '/app/tablet/homeTablet',
                  icon: ReceiptIcon
                },
                {
                  title: t('EWO'),
                  href: '/app/execution/ewo',
                  icon: ReceiptIcon
                },
                {
                  title: t('TAG'),
                  href: '/app/execution/tag',
                  icon: ReceiptIcon
                },
                {
                  title: t('Nuovo TAG'),
                  icon: ReceiptIcon,
                  href: '/app/planning/tag/equipment'
                },
                {
                  title: t('Nuova EWO'),
                  icon: ReceiptIcon,
                  href: '/app/planning/ewo/equipment'
                },
              ]
            }, */
    //Configurazione
    {
      title: 'Configurazione Padre',
      subheader: t(''),
      permission: [1, 8],
      items: [
        {
          title: t('Configurazione'),
          items: [
            {
              title: t('Librerie impianto'),
              icon: FolderIcon,
              href: '/app/management/products',
              items: [
                {
                  title: t('Tipologie Macchine'),
                  href:
                    '/app/configuration/equipmentArchives/libMachineTipology'
                },
                {
                  title: t('Tipologie Gruppi'),
                  href: '/app/configuration/equipmentArchives/libGroup'
                },
                {
                  title: t('Tipologie Sottogruppi'),
                  href: '/app/configuration/equipmentArchives/libSubgroup'
                },
                {
                  title: t('Tipologie Componenti'),
                  href: '/app/configuration/equipmentArchives/libComponent'
                }
              ]
            },
            {
              title: t('Altre Librerie'),
              icon: FolderIcon,
              href: '/app/management/products',
              permission: [1, 8],
              items: [
                {
                  title: t('Costruttori'),
                  href: '/app/configuration/othersArchives/libManufacturer'
                },
                {
                  title: t('Tipologia Lubrificanti'),
                  href: '/app/configuration/othersArchives/libLubrificant'
                },
                {
                  title: t('Grandezze fisiche'),
                  href: '/app/configuration/othersArchives/libPhisicalQty'
                },
                {
                  title: t('Tipologia Materiali'),
                  href: '/app/configuration/othersArchives/libMaterials'
                },
                {
                  title: t('Tipologia Attrezzi'),
                  href: '/app/configuration/othersArchives/libTools'
                },
                {
                  title: t('Tipologia Strumenti'),
                  href: '/app/configuration/othersArchives/libControlTools'
                }
              ]
            },
            {
              title: t('Libreria Skill'),
              href: '/app/extra/forms',
              icon: FolderIcon,
              permission: [1, 2, 3, 5, 8],
              hideWo: true,
              plantWo: plantWoPermission === false,
              items: [
                {
                  title: t('Capacita'),
                  href: '/app/management/libskill/capacity',
                  hideWo: true,
                  plantWo: plantWoPermission === false
                },
                {
                  title: t('Gruppo professionale'),
                  href: '/app/management/libskill/professionalgroups',
                  hideWo: true,
                  plantWo: plantWoPermission === false
                },
                {
                  title: t('Tipologia Skill'),
                  href: '/app/management/libskill/tipology',
                  hideWo: true,
                  plantWo: plantWoPermission === false
                }
              ]
            },
            {
              title: t('Controlli'),
              href: '/app/configuration/controls',
              icon: FolderIcon
            },
            {
              title: t('Menu.Users'),
              href: '/app/configuration/users',
              icon: FolderIcon
            },
            {
              title: t('Menu.Data Ingestion'),
              href: '/app/configuration/dataingestion',
              icon: DbIcon
            },
            {
              title: t('Menu.Other Users'),
              href: '/app/operator/homeOperator',
              icon: DbIcon
            }
          ]
        }
      ]
    }
  ];

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>

        <Box p={2}>
          {sections1.map(
            section =>
              findPermissionItem({
                data: section,
                idprofilo: user.idprofilo
              }) && (
                <List
                  key={section.title + random()}
                  subheader={
                    <ListSubheader disableGutters disableSticky>
                      {section.subheader}
                    </ListSubheader>
                  }
                >
                  {renderNavItems({
                    items: section.items,
                    pathname: location.pathname,
                    plant: user.plantdesc,
                    idprofilo: user.idprofilo
                  })}
                </List>
              )
          )}
        </Box>
        <Divider />
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
